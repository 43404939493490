export default {
  setPredictedData(state, payload) {
    state.predictedData = payload;
  },
  setSectionTitle(state, payload) {
    state.sectionTitle = payload;
  },

  setDataSource(state, payload) {
    state.dataSource = payload;
  },
};
