import index from './index.vue';

export default [
  {
    path: '/faq',
    name: 'Faq',
    component: index,
    meta: {
      title: 'Frequently Asked Questions',
    },
  },
];
