import { render, staticRenderFns } from "./BasePanel.vue?vue&type=template&id=6bb77fce&scoped=true"
import script from "./BasePanel.vue?vue&type=script&lang=js"
export * from "./BasePanel.vue?vue&type=script&lang=js"
import style0 from "./BasePanel.vue?vue&type=style&index=0&id=6bb77fce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bb77fce",
  null
  
)

export default component.exports