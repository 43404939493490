/* eslint-disable max-len */
const dashboardHelp = [
  {
    title: 'How to use the Navigation Bar',
    steps: [
      'Scroll to the top of the page.',
      'On the Navigation bar, select any option to navigate to a different page.',
    ],
    show: false,
  },
  {
    title: 'How to select a dashboard',
    steps: [
      'Scroll to the navigation bar.',
      'On the navigation bar, hover or click on the “Select Dashboard” button.',
      'A drop down will appear, you can select any dashboard from the options provided.',
    ],
    show: false,
  },
  {
    title: 'How to login/Register',
    steps: [
      'On the navigation bar, click on the login/register button.',
      'A drawer will appear on the right.',
      'Input your username and password to proceed or you can login with facebook, gmail or linkedin.',
      'If you don’t have an account, select the create account button, this will direct you to fill in the necessary information to create your account.',
    ],
    show: false,
  },
  {
    title: 'How to select Open Menu',
    steps: [
      'Scroll to the navigation bar.',
      'On the navigation bar, click on the menu icon and select the options from the dropdown menu.',
    ],
    show: false,
  },
  {
    title: 'How to change selected indicator on Indicator Overview',
    steps: [
      'Click on the dropdown arrow next to the main indicator.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to change selected data source on Indicator Overview',
    steps: [
      'Click on the dropdown arrow next to the data source.',
      'Scroll down to the appropriate program area where the desired data source can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired data source into the search field.',
      'When found, click on the desired data source.',
    ],
    show: false,
  },
  {
    title: 'How to change selected location on Indicator Overview',
    steps: [
      'Click on the dropdown arrow next to the location.',
      'Scroll down to the location you want.',
      'You can also type keywords of the desired location into the search field.',
      'When found, click on the desired location.',
    ],
    show: false,
  },
  {
    title: 'How to change selected year on Indicator Overview',
    steps: [
      'Click on the dropdown arrow next to the year.',
      'Scroll down to the year you want.',
      'You can also type keywords of the desired year into the search field.',
      'When found, click on the desired year.',
    ],
    show: false,
  },
  {
    title: 'How to select Target, Numerator and Denominator',
    steps: [
      'Scroll to the target option on the control panel.',
      'Click on the National and SDG section to toggle on and off.',
      'Scroll to the NUM/DENOM section on the control panel.',
      'Click on the ON and OFF button to toggle between states.',
    ],
    show: false,
  },
  {
    title: 'How to use the Indicator Overview Table',
    steps: [
      'Scroll to the indicator overview table.',
      'Click on the  info button to see more information on each section.',
      'Click on the expand icon on the top right of this section to expand.',
      'Click on any data source in the table to visualize the data on the dashboard.',
    ],
    show: false,
  },
  {
    title: 'How to use the comparison chart',
    steps: [
      'Scroll to the comparison chart directly under the indicator overview table.',
      'Click on the line/column button to toggle between data visualizations.',
      'Click on the ON/OFF toggle to activate the confidence range chart.',
      'You can select either the IHME or NNHS data sources.',
      'Click on the hamburger menu to see more information.',
    ],
    show: false,
  },

  {
    title: 'How to use the bar chart',
    steps: [
      'Scroll to the bar chart on the .',
      'The bar chart displays the distribution of data across a specific location.',
      'Hover over any bar to see more information.',
      'Click on any bar to drill down and see more information.',
      'To go back, click on the back button at the top left.',
      'Click on the hamburger menu to see more information.',
    ],
    show: false,
  },
  {
    title: 'How to change selected indicator on zonal analysis',
    steps: [
      'Click on the dropdown arrow next to the main indicator.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to change selected data source on zonal analysis',
    steps: [
      'Click on the dropdown arrow next to the data source.',
      'Scroll down to the data source you want.',
      'You can also type keywords of the desired data source into the search field.',
      'When found, click on the desired data source.',
    ],
    show: false,
  },
  {
    title: 'How to change selected location on zonal analysis',
    steps: [
      'Click on the dropdown arrow next to the location.',
      'Scroll down to the location you want.',
      'You can also type keywords of the desired location into the search field.',
      'When found, click on the desired location.',
    ],
    show: false,
  },
  {
    title: 'How to change selected year on zonal analysis',
    steps: [
      'Click on the dropdown arrow next to the year.',
      'Scroll down to the year you want.',
      'You can also type keywords of the desired year into the search field.',
      'When found, click on the desired year.',
    ],
    show: false,
  },
  {
    title: 'how to select Target',
    steps: [
      'Scroll to the target option on the control panel.',
      'Click on the National and SDG section to toggle on and off.',
    ],
    show: false,
  },
  {
    title: 'How to use the distribution column chart and map',
    steps: [
      'Scroll to the column chart.',
      'Click on the keys below to chart to select what type of data you want to visualize on the chart.',
      'Scroll to the Map on the right.',
      'Click on any state to drill down and see more information about each state.',
      'Any state you select will visualize data on the chart as well.',
      'Click on the expand icon on the top right of either section to view this section in isolation.',
    ],
    show: false,
  },
  {
    title: 'How to select multiple indicators on indicator comparison',
    steps: [
      'Click on the dropdown arrow next to the main indicator.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
      'You can select up to three indicators.',
      'To remove an indicator, click on the “x” button.',
    ],
  },
  {
    title: 'How to change selected data source on indicator comparison',
    steps: [
      'Click on the dropdown arrow next to the data source.',
      'Scroll down to the data source you want.',
      'You can also type keywords of the desired data source into the search field.',
      'When found, click on the desired data source.',
    ],
    show: false,
  },
  {
    title: 'How to change selected location on indicator comparison',
    steps: [
      'Click on the dropdown arrow next to the location.',
      'Scroll down to the location you want.',
      'You can also type keywords of the desired location into the search field.',
      'When found, click on the desired location.',
    ],
    show: false,
  },
  {
    title: 'How to compare data on indicator comparison',
    steps: [
      'Click on the dropdown arrow next to the “Compare by” option.',
      'There are two types of comparison: Period and State.',
      'Click on either option and proceed.',
      'Click on the keys below to chart to select what type of data you want to visualize on the chart.',
    ],
    show: false,
  },
  {
    title: 'How to change selected indicator on data comparison',
    steps: [
      'Click on the dropdown arrow next to the main indicator.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to select multiple data sources to compare on data comparison',
    steps: [
      'Click on the dropdown arrow next to the data source.',
      'Scroll down to the data source.',
      'Click on the data source and select a year.',
      'You can also type keywords of the desired data source into the search field.',
      'You can select up to three data sources.',
      'To remove a data source, click on the “x” button.',
      'On the chart, click on the keys at the bottom of the chart to select what data source you would like to see at a time.',
    ],
    show: false,
  },
  {
    title: 'How to Search program areas & open and close control panel on multi-source comparison',
    steps: [
      'Click on the search icon to search for program areas for each indicator.',
      'Click on the dropdown button on the right to close or open the control panel.',
    ],
    show: false,
  },
  {
    title: 'How to change selected indicator on multi-source comparison',
    steps: [
      'Click on the dropdown arrow next to the main indicator.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to change selected data source on multi-source comparison',
    steps: [
      'Click on the dropdown arrow next to the data source.',
      'Scroll down to the data source.',
      'Click on the data source and select a year.',
      'You can also type keywords of the desired data source into the search field.',
      'When found, click on the desired data source.',
    ],
    show: false,
  },
  {
    title: 'How to change visualizations on multi-source comparison',
    steps: [
      'Click on any button among the four available forms of visualization (Zonal map, state map, line and column).',
      'Toggle between them to select any form of visualization you prefer',
    ],
    show: false,
  },
  {
    title: 'How to change selected year on multi-source comparison',
    steps: [
      'Click on the dropdown arrow next to the year.',
      'Scroll down to the year you want.',
      'You can also type keywords of the desired year into the search field.',
      'When found, click on the desired year.',
    ],
    show: false,
  },
  {
    title: 'How to use the charts and map on multi-source comparison',
    steps: [
      'On the line and bar chart, hover over any data point or bar to see more information.',
      'Click on the keys below the chart to select what type of data you want to visualize on the chart.',
      'On the Map, hover over any state to see more information.',
      'The colors on the map represent varying levels of data for each state.',
      'Click on the hamburger menu to see more information.',
      'Click on the expand icon on the top right of either section to view this section in isolation.',

    ],
    show: false,
  },
  {
    title: 'How to change selected indicator on the Disaggregation section',
    steps: [
      'Click on the dropdown arrow next to the main indicator.',
      'Select desired indicator.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to change Disaggregation type on the Disaggregation section',
    steps: [
      'Click on the dropdown arrow next to the disaggregation type.',
      'Select desired disaggregation type.',
    ],
    show: false,
  },
  {
    title: 'How to change source on the Disaggregation section',
    steps: [
      'Scroll to the disaggregation chart section on the right.',
      'Click on the dropdown arrow labeled source.',
      'Select desired data source.',
      'At the bottom of the chart, you can select from the keys listed, what ever disaggregation you would like to isolate.',
    ],
    show: false,
  },
  {
    title: 'How to Change Selected indicator',
    steps: {
      First_Method: [
        'Click on the dropdown arrow next to the main indicator.',
        'Scroll down  to the appropriate program area where the desired indicator can be found.',
        'Click on the program dropdown.',
        'You can also type keywords of the desired indicator into the search field.',
        'When found, click on the desired indicator.',
      ],
      Second_Method: [
        'Go to the indicator search field at the top of the platform.',
        'Scroll down to the appropriate program area where the desired indicator can be found.',
        'Click on the program dropdown.',
        'You can also type keywords of the desired indicator into the search field.',
        'When found, click on the desired indicator.',
      ],
    },
    show: false,
  },
  {
    title: 'How to change related indicator',
    steps: [
      'Click on the dropdown arrow next to the related indicator you want to change.',
      'Scroll down to the appropriate program area where the desired indicator can be found.',
      'Click on the program dropdown.',
      'You can also type keywords of the desired indicator into the search field.',
      'When found, click on the desired indicator.',
    ],
    show: false,
  },
  {
    title: 'How to check for more information on the indicator',
    steps: [
      'Select the indicator you want to see more information on.',
      // 'On the table, click on the info icon <i data-v-862f69e2 class="fas fa icon fa-info-circle" style="color: #41918E; font-size: 15px;"></i> to the left of the indicator.',
      'On the table, click on the info icon 🛈 to the left of the indicator.',
      'Information on the indicator is given with respect to the data source, therefore, you can switch between the available data sources.',
      'You can also get more information on the indicator by going to the Information Page.',
    ],
    show: false,
  },
  {
    title: 'How to check information on the Data Source',
    steps: [
      // 'On the table, click on the info icon <i data-v-862f69e2 class="fas fa icon fa-info-circle" style="color: #41918E; font-size: 15px;"></i> by the data source you are interested in.',
      'On the table, click on the info icon 🛈 by the data source you are interested in.',
      'You can also get more information on the data sources by going to the information page.',
    ],
    show: false,
  },
  {
    title: 'How to change the dataset showing on all the charts',
    steps: ['Click on the desired dataset on the table.'],
    show: false,
  },
  {
    title: 'How to change the period showing on the Bar chart',
    steps: [
      'Click in the period filter found over the bar chart.',
      'There would be a drop down showing only periods with available data.',
      'Click on the desired period.',
    ],
    show: false,
  },
  {
    title:
      'How to change Location of the data showing on the indicator table and data trend (Line chart) chart',
    steps: [
      'Click on the Location filter found at the top right of the platform.',
      'Search and select the desired location.',
      'Data for the selected location will reflect on the Indicator table and line chart.',
    ],
    show: false,
  },
  {
    title: 'How to change data set showing on the Zonal chart',
    steps: [
      'Select the Dataset filter just above the zonal chart.',
      'Click on the dataset you want to see.',
      'The selected dataset will show on both the zonal chart and map.',
    ],
    show: false,
  },
  {
    title: 'How to change the period on the zonal chart',
    steps: ['Click on the period filter just above the zonal chart.', 'Select the desired period.'],
    show: false,
  },
  {
    title: 'How to compare multiple datasets for an indicator across the states',
    steps: [
      'Go to the multi-dataset comparison panel.',
      'Select the indicator you want to compare from the indicator filter just above the panel.',
      'Select the data sets you want to compare from the filter above the panel.',
      'You can select up to four datasets.',
    ],
    show: false,
  },
  {
    title: 'How to compare different indicators from the same data sets',
    steps: [
      'Open the “Compare Indicators” Panel.',
      'Select the data set you want to compare indicators from on the the filter just above the panel.',
      'Select the indicators you want to compare on the individual charts.',
      'You can change chart types: bar chart, column chart, a zonal chart and a states map for each chart.',
    ],
    show: false,
  },
  {
    title: 'How to download the data from a chart',
    steps: [
      'Click on the download button on each chart.',
      'Select either download as csv or download as xls.',
    ],
    show: false,
  },
  {
    title: 'How to download the chart as an image',
    steps: [
      'Click on the download button on each chart.',
      'Select the picture type you want to download the chart as: png, jpeg, pdf, vector image.',
    ],
    show: false,
  },
  {
    title: 'How to view information on the National target',
    steps: ['Click on the target line.'],
    show: false,
  },
  {
    title: 'How to check the confidence range.',
    steps: [
      'On the table, find the data set you want to check the confidence range for.',
      'If the confidence range is available, there would be a toggle under the data set on the table.',
      'Toggle the switch to view the confidence range.',
    ],
    show: false,
  },
];

export default dashboardHelp;
