import { render, staticRenderFns } from "./ScreenshotManagerPlugin.vue?vue&type=template&id=f857a696&scoped=true"
import script from "./ScreenshotManagerPlugin.vue?vue&type=script&lang=js"
export * from "./ScreenshotManagerPlugin.vue?vue&type=script&lang=js"
import style0 from "./ScreenshotManagerPlugin.vue?vue&type=style&index=0&id=f857a696&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f857a696",
  null
  
)

export default component.exports