export default () => ({
  data: [],
  location: [],
  indicators: [],
  location_hierarchy_level: [],
  valuetypes: [],
  links: [],
  factors: [],
  datasource_specific_indicator: [],
  datasources: [],
  availableDashboardIndicator: [],
  dashboardDataSource: [],
});
