import { render, staticRenderFns } from "./SubCardToggleButton.vue?vue&type=template&id=25c8e4cb&scoped=true"
import script from "./SubCardToggleButton.vue?vue&type=script&lang=js"
export * from "./SubCardToggleButton.vue?vue&type=script&lang=js"
import style0 from "./SubCardToggleButton.vue?vue&type=style&index=0&id=25c8e4cb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c8e4cb",
  null
  
)

export default component.exports