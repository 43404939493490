import index from './index.vue';

export default [
  {
    path: '/create-plugin',
    name: 'Create Plugin',
    component: index,
    meta: {
      title: 'Create Plugin',
    },
  },
];
