export default {
  predictedData: {},
  sectionTitle: '',
  default: {
    indicator: 7,
    datasource: 6,
    location: 1,
    year: null,
  },
  dataSource: 'NHMIS-DHIS2',
};
