export default {
  indicators: [],
  interaction: {},
  interactions: [],
  status: true,
  submittedPlugins: [],
  pluginsImported: [],
  users: [],
  activeDashboards: [],
};
